<template>
  <div class="mh">
    <div class="minBox" :style="{'background-color': styleInfo.bgColor}">
      <!-- 顶部区域 -->
      <div class="top">
        <img class="bookImg" :src="bookInfo.cover" alt="" />
      </div>
      <!-- 主要中间区域 -->
      <div class="main">
        <div class="title">
          <h1>{{bookInfo.title}}</h1>
        </div>
        <p class="content" :style="{color: styleInfo.fontColor}">
          {{bookInfo.content}}
        </p>
        
        <div class="bottomBtn">
          <div
            class="reading"
            @click="jumpToReader"
            :style="{'background': styleInfo.buttonColor}"
          >
            <span>{{styleInfo.buttonWord}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { drpInfo, reportDrp } from '@/http/api'

export default {
  components: {  },
  data() {
    return {
      bookInfo: {
        cover: '',
        title: '',
        content: ''
      },
      styleInfo: { // 样式信息
        bgColor: '#ccc',
        buttonColor: '#977dff',
        fontColor: '#000',
        buttonWord: 'Continue Read',
      },
      buttonUrl: ''
    };
  },
  computed: {},
  watch: {},
  created() {
    // 请求数据
    drpInfo({'lid': this.$route.query.lid}).then(res => {
      if (res.code) {
        this.$router.push({name: 'unfoundLink'})
        return
      }

      // 针对返回值赋值
      this.bookInfo.cover = res.data.top_image
      this.bookInfo.title = res.data.title
      this.bookInfo.content = res.data.content
      
      this.styleInfo.bgColor = res.data.bg_color
      this.styleInfo.buttonColor = res.data.button_color
      this.styleInfo.fontColor = res.data.font_color
      this.styleInfo.buttonWord = res.data.button_word
      this.buttonUrl = res.data.button_url

      // 上报数据
       reportDrp({ lid:this.$route.query.lid, type: 1 }).then(res => { })
    })
  },
  mounted() {},
  methods: {
    // 点击按钮跳转
    jumpToReader() {
      reportDrp({ lid:this.$route.query.lid, type: 2 }).then(res => { }) // 上报数据
      window.location.href = this.buttonUrl
    },
  },
};
</script>
<style lang="scss" scoped>
.minBox {
  .top {
    img {
      width: 100%;
    }
  }

  .main {
    .title {
      margin-top: .5rem;
      h1 {
        font-size: 1.25rem;
        font-weight: 600;
        color: white;
        height: 2rem;
        line-height: 2rem;
      }
    }
    .content {
      font-size: 1rem;    
      padding: 0 0.9375rem;
      white-space: pre-wrap;
      text-align: left;
      line-height: 2;
      padding-bottom: 4rem;
    }
    .bottomBtn {
      position: fixed;
      bottom: 0;
      left: 0;
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 0.9375rem;
      .reading {
        width: 18rem;
        height: 2.625rem;
        // background: linear-gradient(180deg, #c8baff, #977dff);
        box-shadow: 0 0.3125rem 0.9375rem #e4d7ff;
        border-radius: 1.875rem;
        line-height: 2.625rem;
        text-align: center;
        font-size: 1rem;
        color: #fff;
        margin: 0 auto;
        span {
          margin: 0 1rem;
        }
      }
    }
  }
}
</style>
